import * as React from "react";
import CustomerViews from "../components/CustomerViews/CustomerViews";
import HappyFaces from "../components/HappyFaces/HappyFaces";
import HeroSection from "../components/HeroSection/HeroSection";
import Layout from "../components/Layout/Layout";
import Packages from "../components/Packages/Packages";
import Seo from "../components/Seo";
import StagrowServices from "../components/Services/Services";
import StagrowProcess from "../components/StagrowProcess/StagrowProcess";
import Data from "../utils/data";


const IndexPage = () => {
  const [loader,setLoader] = React.useState<boolean>(true)
  const strapiGlobal = Data().strapiHomePage;

  React.useEffect(()=>{
    if(strapiGlobal) setLoader(false)
  },[strapiGlobal])

  // if(loader)
  //   return <div className="d-flex align-items-center justify-content-center w-full " style={{height:'100vh'}}>Loading .... </div>
  return (
  
    <>
      <Seo
        title={strapiGlobal.seo.title}
        description={strapiGlobal.seo.description}
        keywords="buy instagram likes, followers and views"
        />
      <Layout>
        <HeroSection homeData={strapiGlobal.heroSection} />
        <StagrowProcess data={strapiGlobal.workSection} />
        <StagrowServices data={strapiGlobal.serviceSection} />
        <Packages data={strapiGlobal.packagesSection} />
        <HappyFaces />
        <CustomerViews />
      </Layout>
    </>
        
  );
};

export default IndexPage;
